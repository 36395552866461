import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Button, 
  CircularProgress, 
  Container, 
  List, 
  ListItem, 
  ListItemText, 
  Paper, 
  Typography,
  Avatar,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { fetchProjectDetail, createConversation } from '../services/api';
import { ProjectDetail as ProjectDetailType } from '../types/project';
import { useAuth0 } from '@auth0/auth0-react';

const ProjectDetail: React.FC = () => {
  const { project_id } = useParams<{ project_id: string }>();
  const [project, setProject] = useState<ProjectDetailType | null>(null);
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const project = await fetchProjectDetail(project_id!, token!);
        console.log(project);
        setProject(project);
      } catch (error) {
        console.error('Error fetching the project', error);
      }
    };
    fetchData();
  }, [project_id]);

  const handleCreateConversation = async () => {
    try {
      const token = await getAccessTokenSilently();
      console.log('Creating conversation...', project_id);
      const newConversation = await createConversation(project_id!, token!);
      navigate(`/projects/${project_id}/chats/${newConversation.chat_id}`);
    } catch (error) {
      console.error('Error creating conversation', error);
    }
  };

  const handleConversationClick = (chat_id: string) => {
    navigate(`/projects/${project_id}/chats/${chat_id}`);
  };

  if (!project) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar
            alt={project.name}
            src="../img/noodle.png"
            sx={{ width: 56, height: 56, mr: 2 }}
          />
          <Typography variant="h4" gutterBottom>
            {project.name}
          </Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {project.description}
        </Typography>
        <Box my={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom>
              Conversations
            </Typography>
            <IconButton onClick={handleCreateConversation} color="primary">
              <AddIcon />
            </IconButton>
          </Box>
          {project.conversations.length > 0 ? (
            <List>
              {project.conversations.map((conversation, index) => (
                <ListItem key={index} button onClick={() => handleConversationClick(conversation.chat_id)}>
                  <ListItemText primary={conversation.chat_id} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No conversations
            </Typography>
          )}
        </Box>
        <Box my={4}>
          <Typography variant="h6" gutterBottom>
            Artifacts
          </Typography>
          {project.artifacts.length > 0 ? (
            <List>
              {project.artifacts.map((artifact, index) => (
                <ListItem key={index}>
                  <ListItemText primary={artifact} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No artifacts
            </Typography>
          )}
        </Box>
        <Box my={4}>
          <Typography variant="h6" gutterBottom>
            Team
          </Typography>
          <List>
            {project.team.map((member, index) => (
              <ListItem key={index}>
                <ListItemText primary={member} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Paper>
    </Container>
  );
};

export default ProjectDetail;