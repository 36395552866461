import React from "react";
import {
  Route,
  Router,
  Routes,
  useLocation,
  useNavigate,
  Navigator,
} from "react-router-dom";
import ProjectDetail from "./ProjectDetail";
import { Box, Switch } from "@mui/material";
import ChooseProject from "./ChooseProject";
import ChatComponent from "./ChatComponent";

const MainWindow: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        flex: 1,
        p: 2,
        boxSizing: "border-box",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Routes>
        <Route
          path="/projects/:project_id/chats/:chat_id"
          element={<ChatComponent />}
        />
      </Routes>
    </Box>
  );
};

export default MainWindow;
