import axios from 'axios';
import { Project, ProjectDetail } from '../types/project';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchProjectsByUserId = async (token: string): Promise<Project[]> => {
  try {
    const response = await axios.get(`${API_URL}/projects`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};

export const fetchPersonas = async (token: string): Promise<any[]> => {
  try {
    const response = await axios.get(`${API_URL}/personas`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};

export const createProject = async (project: Project, token: string): Promise<Project> => {
  try {
    const response = await axios.post(`${API_URL}/projects`, project, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating project:', error);
    throw error;
  }
}

export const fetchProjectDetail = async (projectId: string, token: string): Promise<ProjectDetail> => {
  try {
    const response = await axios.get(`${API_URL}/projects/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching project detail for project ID ${projectId}:`, error);
    throw error;
  }
};

export const createConversation = async (project_id: string, token: string): Promise<{ chat_id: string }> => {
  try {
    console.log("Calling POST chats/ with project_id", project_id);
    const response = await axios.post(`${API_URL}/chats`, { project_id: project_id }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error creating conversation for project ID ${project_id}:`, error);
    throw error;
  }
};

export const getConversation = async (project_id: string, chat_id: string, token: string): Promise<any> => {
  try {
    console.log("Calling POST chats/ with project_id", project_id);
    const response = await axios.get(`${API_URL}/chats/${chat_id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error getting chat for project ID ${project_id} and chat ID ${chat_id}:`, error);
    throw error;
  }
};

export const getOrCreateUser = async (user: any, token: string): Promise<any> => {
  try {
    console.log("Calling POST user with user", user);
    const response = await axios.post(`${API_URL}/users`, user, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error getting/creating user for:`, user, error);
    throw error;
  }
};