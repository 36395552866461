import React, { useEffect, useRef, useState, useCallback } from 'react';
import { 
  Box, 
  IconButton, 
  Tooltip, 
  Typography, 
  Fade, 
  CircularProgress 
} from '@mui/material';
import { 
  Mic as MicIcon, 
  MicOff as MicOffIcon 
} from '@mui/icons-material';
import LilDude from './LilDude';

interface BrowserSTTTranscriberProps {
  onUtterance: (text: string) => void;
  onTranscriptionEnd: () => void;
}

const BrowserSTTTranscriber: React.FC<BrowserSTTTranscriberProps> = ({
  onUtterance,
  onTranscriptionEnd,
}) => {
  const [isMuted, setIsMuted] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef<SpeechRecognition | null>(null);
  const timeoutRef = useRef<number | null>(null);

  const startRecognition = useCallback(() => {
    if (recognitionRef.current && !isMuted) {
      try {
        recognitionRef.current.start();
        setIsListening(true);
      } catch (error) {
        console.error('Error starting speech recognition:', error);
        setIsListening(false);
      }
    }
  }, [isMuted]);

  const stopRecognition = useCallback(() => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsListening(false);
    }
  }, []);

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      setErrorMessage('Speech recognition is not supported in this browser.');
      return;
    }

    recognitionRef.current = new SpeechRecognition();
    const recognition = recognitionRef.current;

    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      if(!isMuted) {
        const transcript = event.results[0][0].transcript;
        onUtterance(transcript);
      }
    };

    recognition.onend = () => {
      setIsListening(false);
      if (!isMuted) {
        timeoutRef.current = window.setTimeout(() => {
          startRecognition();
        }, 1000);
      } else {
        onTranscriptionEnd();
      }
    };

    recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
      console.error('Speech recognition error:', event.error);
      setIsListening(false);
    };

    if (!isMuted) {
      startRecognition();
    }

    return () => {
      stopRecognition();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isMuted, onUtterance, onTranscriptionEnd, startRecognition, stopRecognition]);

  const toggleMute = () => {
    setIsMuted((prevMuted) => !prevMuted);
    stopRecognition();
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <LilDude isMuted={isMuted} size={100} onClick={toggleMute}/>
    </Box>
  );
};

export default BrowserSTTTranscriber;