import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createProject, fetchProjectsByUserId } from '../services/api';
import { Project } from '../types/project';
import { useAuth0 } from '@auth0/auth0-react';

export interface ProjectListProps {
}

const ProjectList: React.FC<ProjectListProps> = ({ }) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const result = await fetchProjectsByUserId(token);
        setProjects(result);
      } catch (error) {
        console.error('Error fetching the projects', error);
      }
    };
    fetchData();
  }, [isAuthenticated, loginWithRedirect, getAccessTokenSilently]);

  const handleItemClick = (project_id: string) => {
    navigate(`/projects/${project_id}`);
  };

  const handleCreateProject = async () => {
    try {
      const token = await getAccessTokenSilently();
      console.log('Creating project...');
      const project: Project = {
        name: 'Default Project',
        description: 'This is a new project'
      };
      const newProject = await createProject(project, token!);
      navigate(`/projects/${newProject.id}`);
    } catch (error) {
      console.error('Error creating conversation', error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ marginBottom: 2 }}>
        Project List
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
        <Box>
          <button onClick={handleCreateProject}>Create Project</button>
        </Box>
      </Box>
      <List>
        {projects.map((project) => (
          <ListItem key={project.id} button onClick={() => handleItemClick(project.id ? project.id : "")}>
            <ListItemAvatar>
              <Avatar alt={project.name} src="img/noodle.png" />
            </ListItemAvatar>
            <ListItemText primary={project.name} secondary={project.description} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ProjectList;