import React, { useRef } from 'react';
import { Box, IconButton, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Routes, Route, useLocation } from 'react-router-dom';
import ProjectList from './ProjectList';
import ProjectDetail from './ProjectDetail';

interface SideBarProps {
  toggleSidebar: () => void;
  width: number;
  onResize: (newWidth: number) => void;
}

const SideBar: React.FC<SideBarProps> = ({ toggleSidebar, width, onResize }) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const disableTextSelection = () => {
    document.body.style.userSelect = 'none';
  };

  const enableTextSelection = () => {
    document.body.style.userSelect = '';
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    const startX = e.clientX;
    const startWidth = width;

    const handleMouseMove = (e: MouseEvent) => {
      const newWidth = startWidth + e.clientX - startX;
      onResize(newWidth);
    };

    const handleMouseUp = () => {
      enableTextSelection();
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    disableTextSelection();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <Drawer
      variant="persistent"
      open={true}
      ModalProps={{ keepMounted: true }}
      PaperProps={{ sx: { width: `${width}px`, position: 'relative', padding: '1rem' } }}
    >
      <Box ref={sidebarRef} role="presentation">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '1rem',
          }}
        >
          <IconButton onClick={toggleSidebar}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              transform: location.pathname === '/' ? 'translateX(0)' : 'translateX(-100%)',
              transition: 'transform 0.3s ease',
            }}
          >
            <Box sx={{ flex: '1 0 100%', overflowY: 'auto' }}>
              <ProjectList />
            </Box>
            <Box sx={{ flex: '1 0 100%', overflowY: 'auto' }}>
              <Routes>
                <Route path="/projects/:project_id/*" element={<ProjectDetail />} />
              </Routes>
            </Box>
          </Box>
        </Box>
        <Box
          onMouseDown={handleMouseDown}
          sx={{
            width: '5px',
            cursor: 'ew-resize',
            height: '100%',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        />
      </Box>
    </Drawer>
  );
};

export default SideBar;