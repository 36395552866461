import React, { useRef, useEffect, useState } from 'react';
import { speechService } from '../services/SpeechService';

interface LilDudeProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
  isMuted?: boolean;
}

const LilDude: React.FC<LilDudeProps> = ({ size = 200, isMuted = false, ...rest }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [debugInfo, setDebugInfo] = useState<string>('');
  const particlesRef = useRef<{ x: number; y: number; alpha: number; size: number; targetX: number; targetY: number; directionX: number; directionY: number; color: string; }[]>([]);

  // Configurable options
  const circleColorStart = '#f1f1f1'; // Light grey
  const circleColorEnd = '#d1d1d1'; // Slightly darker grey
  let particleColors = isMuted ? ['#888888'] : ['#00BFFF', '#33A1DD']; // Multi-colored particles
  const particleCount = 3000;
  const retargetIntervalRange = [50, 600]; // in milliseconds
  const particleRadiusVariance = 60;
  const idleDriftAmount = 0.5; // Reduced drift for smoother motion
  let biasDirection = Math.PI / 4; // 45 degrees
  let biasMagnitude = 0.8; // Bias strength

  useEffect(() => {
    particleColors = isMuted ? ['#888888'] : ['#00BFFF', '#33A1DD'];
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    let animationFrameId: number;

    const initParticles = () => {
      particlesRef.current = Array.from({ length: particleCount }, (_, i) => {
        const angle = Math.random() * Math.PI * 2;
        const radius = Math.random() * (size / 2 - 10) + particleRadiusVariance;
        const targetX = canvas.width / 2 + radius * Math.cos(angle);
        const targetY = canvas.height / 2 + radius * Math.sin(angle);
        const directionX = Math.random() * 2 - 1;
        const directionY = Math.random() * 2 - 1;
        const color = particleColors[i % particleColors.length];
        return {
          x: targetX,
          y: targetY,
          targetX,
          targetY,
          alpha: Math.random() * 0.5 + 0.5,
          size: Math.random() * size * 0.05 + size * 0.01,
          directionX,
          directionY,
          color,
        };
      });
    };

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const gradient = ctx.createRadialGradient(
        canvas.width / 2, canvas.height / 2, 0,
        canvas.width / 2, canvas.height / 2, size / 2
      );
      gradient.addColorStop(0, circleColorStart);
      gradient.addColorStop(1, circleColorEnd);

      ctx.save();
      ctx.beginPath();
      ctx.arc(canvas.width / 2, canvas.height / 2, size / 2 - 5, 0, Math.PI * 2);
      ctx.clip();
      ctx.fillStyle = gradient;
      ctx.fill();

      ctx.globalCompositeOperation = 'multiply';

      particlesRef.current.forEach((particle) => {
        const drift = isSpeaking ? 0 : idleDriftAmount;
        const biasX = Math.cos(biasDirection) * biasMagnitude;
        const biasY = Math.sin(biasDirection) * biasMagnitude;

        particle.x += (particle.targetX - particle.x) * 0.01 + particle.directionX * drift + biasX;
        particle.y += (particle.targetY - particle.y) * 0.01 + particle.directionY * drift + biasY;
        particle.alpha += (0.5 - particle.alpha) * 0.05;

        // Gradually change direction for smoother, organic movement
        particle.directionX += (Math.random() - 0.5) * 0.02;
        particle.directionY += (Math.random() - 0.5) * 0.02;

        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(${parseInt(particle.color.slice(1, 3), 16)}, ${parseInt(particle.color.slice(3, 5), 16)}, ${parseInt(particle.color.slice(5, 7), 16)}, ${particle.alpha})`;
        ctx.fill();
      });

      ctx.restore();

      setDebugInfo(isSpeaking ? 'Speaking...' : 'Idle');
      animationFrameId = requestAnimationFrame(draw);
    };

    initParticles();
    animationFrameId = requestAnimationFrame(draw);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [size, isMuted]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const updateParticleTargets = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      particlesRef.current.forEach((particle) => {
        const angle = Math.random() * Math.PI * 2;
        const radius = Math.random() * (size / 2 - 10) + particleRadiusVariance;
        particle.targetX = canvas.width / 2 + radius * Math.cos(angle);
        particle.targetY = canvas.height / 2 + radius * Math.sin(angle);
      });
    };

    const setRandomInterval = () => {
      if (intervalId) clearInterval(intervalId);
      const randomInterval = Math.random() * (retargetIntervalRange[1] - retargetIntervalRange[0]) + retargetIntervalRange[0];
      intervalId = setInterval(() => {
        if (isSpeaking) updateParticleTargets();
        setRandomInterval();
        // change bias angle slightly
        biasDirection += Math.random() * 0.1 - 0.05;
        biasMagnitude = Math.random() * 0.2 - 0.1;
        // clip bias magnitude
        biasMagnitude = Math.max(0.2, Math.min(1, biasMagnitude));
      }, randomInterval);
    };

    if (isSpeaking) {
      setRandomInterval();
    } else if (intervalId) {
      clearInterval(intervalId);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isSpeaking]);

  useEffect(() => {
    const handleSpeechStart = () => {
      setIsSpeaking(true);
    };

    const handleSpeechEnd = () => {
      setIsSpeaking(false);
    };

    speechService.addEventListener('speechstart', handleSpeechStart);
    speechService.addEventListener('speechend', handleSpeechEnd);

    return () => {
      speechService.removeEventListener('speechstart', handleSpeechStart);
      speechService.removeEventListener('speechend', handleSpeechEnd);
    };
  }, []);

  return (
    <div {...rest}>
      <canvas ref={canvasRef} width={size} height={size} />
      {/* <div>{debugInfo}</div> */}
    </div>
  );
};

export default LilDude;