// TTSComponent.tsx

import React, { useState, useEffect } from 'react';
import { 
  Button, 
  ButtonGroup, 
  IconButton, 
  Tooltip, 
  Box,
  Typography
} from '@mui/material';
import { 
  VolumeOff as MuteIcon, 
  VolumeUp as UnmuteIcon, 
  Stop as StopIcon 
} from '@mui/icons-material';
import { speechService } from '../services/SpeechService';
import LilDude from './LilDude';

const TTSComponent: React.FC = () => {
    const [state, setState] = useState(speechService.getState());

    useEffect(() => {
        const updateState = () => setState(speechService.getState());
        speechService.addListener(updateState);
        return () => speechService.removeListener(updateState);
    }, []);

    const handleVoiceChange = (voiceName: string) => {
        const selectedVoice = state.voices.find((voice: any) => voice.name === voiceName);
        if (selectedVoice) {
            speechService.setSelectedVoice(selectedVoice);
        }
    };

    const getPreferredVoices = () => {
        const preferredVoices = [
            { name: 'Google Deutsch', lang: 'de-DE' },
            { name: 'Google UK English Female', lang: 'en-GB' },
            { name: 'Google français', lang: 'fr-FR' }
        ];

        return preferredVoices.map(pv => {
            const voice = state.voices.find((v: any) => v.name === pv.name && v.lang === pv.lang);
            if (voice) return voice;

            // If preferred voice not found, find a suitable alternative
            const altVoice = state.voices.find((v: any) => v.lang === pv.lang && v.name.toLowerCase().includes('female'));
            return altVoice || state.voices.find((v: any) => v.lang === pv.lang) || state.voices[0];
        });
    };

    const preferredVoices = getPreferredVoices();

    const handleMuteToggle = () => {
        speechService.toggleTTS();
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <Tooltip title={state.isTTSEnabled ? "Mute" : "Unmute"} sx={{"visibility": "hidden"}}>
                <IconButton onClick={handleMuteToggle} color="primary">
                    {!state.isTTSEnabled ? <MuteIcon /> : <UnmuteIcon />}
                </IconButton>
            </Tooltip>
            <ButtonGroup variant="contained" aria-label="voice selection button group" sx={{ margin: '0 auto' }}>
                {preferredVoices.map((voice: any) => (
                    <Button
                        key={voice.name}
                        onClick={() => handleVoiceChange(voice.name)}
                        variant={state.selectedVoice?.name === voice.name ? 'contained' : 'outlined'}
                        color={state.selectedVoice?.name === voice.name ? 'primary' : 'inherit'}
                    >
                        {voice.lang.split('-')[0].toUpperCase()}
                    </Button>
                ))}
            </ButtonGroup>
            <Tooltip title={state.isTTSEnabled ? "Mute" : "Unmute"}>
                <IconButton onClick={handleMuteToggle} color="primary">
                    {!state.isTTSEnabled ? <MuteIcon /> : <UnmuteIcon />}
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default TTSComponent;