import React, { useState, useRef, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  useTheme,
  Popper,
  ClickAwayListener,
  Avatar
} from '@mui/material';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useColorMode } from '../ColorModeContext';
import LoginButton from './LoginButton';
import ProfileDetails from './ProfileDetails';
import { useAuth0 } from '@auth0/auth0-react';
import { getOrCreateUser } from '../services/api';

interface HeaderProps {
  sidebarOpen: boolean;
  sidebarWidth: number;
  toggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ sidebarOpen, sidebarWidth, toggleSidebar }) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  
  const colorMode = useColorMode();
  const theme = useTheme();

  const [profileOpen, setProfileOpen] = useState(false);
  const [server_user, setServerUser] = useState({} as any);
  const accountButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const getCreateUser = async () => {
      if (isAuthenticated && user && !isLoading) {
        const token = await getAccessTokenSilently();
        const dude = await getOrCreateUser({
          "sub": user.sub,
          "name": user.name,
          "email": user.email,
          "picture": user.picture,
          "nickname": user.nickname,
          "given_name": user.given_name,
          "family_name": user.family_name,
          "email_verified": user.email_verified,
          "updated_at": user.updated_at
        }, token);
        setServerUser(dude);
      }
    }
    getCreateUser();
  }, [isAuthenticated, user, isLoading, getAccessTokenSilently]);

  const handleToggleProfile = () => {
    setProfileOpen((prevOpen) => !prevOpen);
  };

  const handleCloseProfile = (event: MouseEvent | TouchEvent) => {
    if (accountButtonRef.current && accountButtonRef.current.contains(event.target as Node)) {
      return;
    }
    setProfileOpen(false);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: theme.palette.background.default,
        zIndex: theme.zIndex.drawer + 1, // Ensure header is above the sidebar
        width: `calc(100% - ${sidebarOpen ? sidebarWidth : 0}px)`,
        transition: 'width 0.3s ease',
      }}
    >
      <Toolbar>
        {!sidebarOpen && (
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleSidebar} sx={{ color: theme.palette.text.primary }}>
            <SpaceDashboardIcon />
          </IconButton>
        )}
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
            ARTIFA.XXX
          </Typography>
        </Box>
        <IconButton color="inherit" onClick={colorMode.toggleColorMode} sx={{ color: theme.palette.text.primary }}>
          {colorMode.mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
        </IconButton>
        {isAuthenticated && (
          <ClickAwayListener onClickAway={handleCloseProfile}>
            <Box>
              <IconButton
                color="inherit"
                sx={{ color: theme.palette.text.primary }}
                ref={accountButtonRef}
                onClick={handleToggleProfile}
              >
                <Avatar src={server_user?.picture} alt={server_user?.name} />
              </IconButton>
              <Popper open={profileOpen} anchorEl={accountButtonRef.current} placement="bottom-end">
                <ProfileDetails />
              </Popper>
            </Box>
          </ClickAwayListener>
        )}
        {!isAuthenticated && (
          <LoginButton />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;