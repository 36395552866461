import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, TextField, IconButton, CircularProgress, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useColorMode } from '../ColorModeContext';
import TextBubble from './TextBubble';
import { useWebSocket } from '../App';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { getConversation } from '../services/api';
import { v4 as uuidv4 } from 'uuid';
import { speechService } from '../services/SpeechService';
import BrowserSTTTranscriber from './BrowserSTTTranscriber';
import TTSComponent from './TTSComponent';
import LilDude from './LilDude';
import { fetchPersonas } from '../services/api';

interface TextMessage {
  message_id: string;
  timestamp: number;
  role: string;
  content: string;
  chat_id?: string;
  username?: string;
  user_id?: string;
  image?: string;
  type: string;
}

interface ImageMessage {
  message_id: string;
  timestamp: number;
  role: string;
  image_url: string;
  chat_id?: string;
  username?: string;
  user_id?: string;
  image?: string;
  image_filename?: string;
  type: string;
}

type Message = TextMessage | ImageMessage;

const ChatComponent: React.FC = () => {
  const { project_id, chat_id } = useParams<{ project_id: string; chat_id: string }>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { mode } = useColorMode();
  const { sendMessage, subscribe, unsubscribe } = useWebSocket();
  const { user, isAuthenticated, isLoading: authLoading, getAccessTokenSilently } = useAuth0();
  const [speak, setSpeak] = useState<(text: string) => void>(() => () => { });

  // Load Chat from router param
  useEffect(() => {
    const fetchChat = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const chat = await getConversation(project_id!, chat_id!, token);
        setMessages(chat.messages);
      } catch (error) {
        console.error('Error fetching chat:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (chat_id) {
      fetchChat();
    }
  }, [chat_id]);

  const [personas, setPersonas] = useState<string[]>([]);
  const [selectedPersona, setSelectedPersona] = useState<string | null>(null);

  // Fetch personas
  useEffect(() => {
    const loadPersonas = async () => {
      try {
        const token = await getAccessTokenSilently();
        const personasData = await fetchPersonas(token);
        setPersonas(personasData);
        console.log("Personas", personasData);
        if (personasData.length > 0) {
          setSelectedPersona(personasData[0]);
        }
      } catch (error) {
        console.error('Error fetching personas:', error);
      }
    };

    loadPersonas();
  }, [getAccessTokenSilently]);

  const handlePersonaChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedPersona(event.target.value as string);
  };

  // Scroll to the bottom of the chat
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    subscribe('message', (payload: any) => {
      // On websocket message, add the message to the chat
      console.log("Received message", payload);
      setMessages(prevMessages => [
        ...prevMessages,
        {
          type: 'text',
          message_id: payload.chat_id,
          content: payload.content,
          role: payload.role,
          timestamp: payload.timestamp,
          username: payload.username,
          image: payload.image,
        }
      ]);

      // Add the new message to the speech queue
      console.log("Speaking", payload.content);
      speechService.clobberSpeechQueue(payload.content);
    });

    subscribe('image', (payload: any) => {
      // On websocket message, add the message to the chat
      setMessages(prevMessages => [
        ...prevMessages,
        {
          type: 'image',
          message_id: payload.chat_id,
          image_url: payload.image_url,
          role: payload.role,
          timestamp: payload.timestamp,
          username: payload.username,
          image: payload.image,
          image_filename: payload.image_filename,
        }
      ]);

      // Add the new message to the speech queue
      console.log("Showing Image", payload.image_filename);
    });

    return () => {
      unsubscribe('message');
      unsubscribe('image');
    };
  }, [subscribe, unsubscribe]);

  // Send user message
  const handleSendMessage = () => {
    if (inputValue.trim()) {
      // Add user message to the chat
      setMessages(prevMessages => [...prevMessages, {
        message_id: uuidv4(),
        content: inputValue,
        role: "user",
        timestamp: Date.now(),
        username: user?.name || 'User',
        image: user?.picture || "img/noodle.png",
        type: 'text',
      }]);

      // Send the Websocket message
      sendMessage('message', {
        content: inputValue,
        chat_id: chat_id,
        requested_bot_id: selectedPersona
      });

      setInputValue('');
    }
  };

  // Send message on Enter key press
  const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      backgroundColor: mode === 'light' ? '#f0f0f0' : '#121212',
    },
    messagesContainer: {
      flex: 1,
      overflowY: 'auto',
      padding: 2,
      display: 'flex',
      flexDirection: 'column',
    },
    inputContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 2,
      borderTop: '1px solid',
      borderColor: mode === 'light' ? '#ddd' : '#333',
      backgroundColor: mode === 'light' ? '#ffffff' : '#1d1d1d',
      bottom: 0,
      width: '100%',
    },
    inputWrapper: {
      display: 'flex',
      maxWidth: '900px',
      width: '100%',
    },
    textField: {
      marginRight: 1,
      '& .MuiOutlinedInput-root': {
        padding: 1,
        backgroundColor: mode === 'light' ? '#ffffff' : '#1d1d1d',
      },
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    image: {
      maxHeight: "50vh",
      maxWidth: "100%",
      width: "fit-content",
      display: 'block',
      margin: 'auto',
    }
  };

  if (isLoading) {
    return (
      <Box sx={styles.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  const handleOnUtterance = (text: string) => {
    // Add user message to the chat
    setMessages(prevMessages => [...prevMessages, {
      message_id: uuidv4(),
      content: text,
      role: "user",
      timestamp: Date.now(),
      username: user?.name || 'User',
      image: user?.picture || "img/noodle.png",
      type: 'text'
    }]);

    sendMessage('message', {
      content: text,
      chat_id: chat_id,
      requested_bot_id: selectedPersona
    });
  };
  
  
  return (
    <Box sx={styles.container} data-testid="chat-component">
      <Box sx={styles.messagesContainer}>
        {messages.map((message) => {
          if ('content' in message) {
            return (
              <TextBubble
                key={message.message_id}
                role={message.role}
                message_id={message.message_id}
                content={message.content}
                timestamp={message.timestamp}
                image={message.image!}
                username={message.username!}
              />
            );
          } else if ('image_url' in message) {
            return (
              <img
                src={process.env.REACT_APP_API_URL + message.image_filename!.replace("data/", "/")}
                key={message.message_id}
                alt={message.image_filename}
                style={styles.image}
              />
            );
          }
          return null;
        })}
        <div ref={messagesEndRef} />
      </Box>
      <Box>
        <BrowserSTTTranscriber
          onUtterance={handleOnUtterance}
          onTranscriptionEnd={() => console.log('Transcription ended')}
        />
        <TTSComponent />
      </Box>
      <Box sx={{ padding: 2 }}>
        <TextField
          select
          label="Select Persona"
          value={selectedPersona}
          onChange={handlePersonaChange}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
          fullWidth
        >
          {personas.map((persona:any) => (
            <option key={persona} value={persona}>
              {persona}
            </option>
          ))}
        </TextField>
      </Box>
    </Box>
  );
};

export default ChatComponent;