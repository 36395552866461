import React, { CSSProperties, useCallback, useState } from 'react';
import { Box, IconButton, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useColorMode } from '../ColorModeContext';
import ReactMarkdown from 'react-markdown';
// import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";

interface CopyButtonProps {
  code: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ code }) => {
  const [open, setOpen] = useState(false);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(code).then(() => {
      setOpen(true);
    });
  }, [code]);

  return (
    <>
      <IconButton
        onClick={handleCopy}
        size="small"
        sx={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          color: 'inherit',
        }}
      >
        <ContentCopyIcon fontSize="small" />
      </IconButton>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        message="Copied to clipboard"
      />
    </>
  );
};

const MarkdownRenderer: React.FC<{ content: string }> = ({ content }) => {
  const { mode } = useColorMode();

  return (
    <Box
      sx={{
        '& p': {
          margin: '0 0 16px',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          fontSize: '1rem',
          lineHeight: 1.5,
        },
        '& h1, & h2, & h3': {
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          fontWeight: 400,
          margin: '0 0 16px',
        },
        '& h1': { fontSize: '2.125rem', lineHeight: 1.235, letterSpacing: '0.00735em' },
        '& h2': { fontSize: '1.5rem', lineHeight: 1.334 },
        '& h3': { fontSize: '1.25rem', lineHeight: 1.6, letterSpacing: '0.0075em' },
        '& a': {
          color: mode === 'light' ? '#1976d2' : '#90caf9',
          textDecoration: 'none',
          '&:hover': { textDecoration: 'underline' },
        },
        '& code': {
          backgroundColor: mode === 'light' ? '#f5f5f5' : '#333',
          padding: '2px 4px',
          borderRadius: '4px',
          width: '100%',
          fontFamily: 'Consolas, "Courier New", monospace',
        },
        '& pre': {
          padding: '16px',
          borderRadius: '4px',
          overflow: 'auto',
          position: 'relative',
        },
      }}
    >
      <ReactMarkdown
        components={{
          code({ node, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return match ? (
              <Box sx={{ position: 'relative' }}>
                <CodeBlock
                  text={String(children).replace(/\n$/, '')}
                  language={match[1]}
                  showLineNumbers={true}
                  theme={dracula}
                />
                <CopyButton code={String(children).replace(/\n$/, '')} />
              </Box>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      >
        {content}
      </ReactMarkdown>
    </Box>
  );
};

export default MarkdownRenderer;